import React from 'react'
import { PaddedContent } from '@aserto/aserto-react-components'

import Hero from '../components/Hero'

const Home = () => (
  <PaddedContent mobileBreakPoint={1200}>
    <Hero />
  </PaddedContent>
)

export default Home
