import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Breadcrumb, PaddedContent, PageHeader } from '@aserto/aserto-react-components'

import noPolicyIcon from '../assets/no-policies.svg'
import { SpinnerFull } from '../components/common/SpinnerFull'
import { PolicyBrowser } from '../components/policies/PolicyBrowser'
import { PolicyFile } from '../types'
import { useAppContext } from '../utils/appContext'
import { ensureError } from '../utils/general'
import { useAuthorizerApi } from '../utils/useAuthorizerApi'

const Container = styled(PaddedContent)`
  overflow-x: auto;
`

const PolicyContainer = styled.div`
  overflow: hidden;
`

const EmptyPolicyBrowser = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 16px;
  img {
    margin: 60px auto auto;
  }
  div {
    margin-top: 22px;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.grey70};
    strong {
      color: ${({ theme }) => theme.grey100};
    }
  }
`

const Policy = () => {
  const { id } = useParams<{ id: string }>()
  const [loading, setLoading] = useState(false)
  const [policyFiles, setPolicyFiles] = useState<Array<PolicyFile>>([])
  const [policyName, setPolicyName] = useState<string>('')
  const [policyId, setPolicyId] = useState<string>('')
  const { setError: setContextError } = useAppContext()
  const breadcrumbTitle = policyName
  const { query } = useAuthorizerApi()

  const load = useCallback(async () => {
    try {
      setLoading(true)
      const responseData = await query('get', `/policy/policies/${id}`)
      setPolicyName(responseData && responseData.name)
      setPolicyId(responseData && responseData.id)
      const policies = responseData && responseData.policies
      policies.sort((a: PolicyFile, b: PolicyFile) => a.name.localeCompare(b.name))
      setPolicyFiles(policies)
    } catch (err) {
      setPolicyName('')
      setPolicyFiles([])
      setContextError(ensureError(err))
    } finally {
      setLoading(false)
    }
  }, [id])

  useEffect(() => {
    load()
  }, [load])

  if (loading) {
    return <SpinnerFull />
  }

  return policyFiles ? (
    <PolicyContainer>
      <PageHeader hasBorderBottom load={load} loading={loading} mobileBreakpoint={1200}>
        <Breadcrumb
          breadcrumbText="Policies"
          breadcrumbUrl="/ui/policies"
          title={breadcrumbTitle}
        />
      </PageHeader>
      <Container mobileBreakPoint={1200} paddingTop={182}>
        {policyFiles.length > 0 ? (
          <PolicyBrowser id={policyId} policies={policyFiles} />
        ) : (
          <EmptyPolicyBrowser>
            <img alt="empty policy" src={noPolicyIcon} />
            <div>
              You don't have any policy files for the policy <strong>{breadcrumbTitle}</strong>.
            </div>
          </EmptyPolicyBrowser>
        )}
      </Container>
    </PolicyContainer>
  ) : (
    <div />
  )
}

export default Policy
