import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  Breadcrumb,
  Highlight,
  PaddedContent,
  PageHeader,
  PolicyCard,
} from '@aserto/aserto-react-components'

import { Policy } from '../../types'
import { ensureError } from '../../utils/general'
import { useAuthorizerApi } from '../../utils/useAuthorizerApi'

const CardGrid = styled.div`
  padding: 20px;
  display: grid;
  overflow-x: hidden;
  grid-template-columns: repeat(auto-fit, 426px);
  grid-template-rows: repeat(auto-fit, 102px);
  gap: 20px;
  @media (max-width: 500px) {
    grid-template-rows: repeat(auto-fit, 91px);
    grid-template-columns: 1fr;
    gap: 10px;
  }
`

const Policies = () => {
  const history = useHistory()
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)
  const [policies, setPolicies] = useState<Policy[]>([])
  const { query } = useAuthorizerApi()
  const breadcrumbTitle = 'Policies'
  const load = async () => {
    try {
      setLoading(true)
      const responseData = await query('get', `/policy/policies`)
      if (!responseData || !responseData.results) {
        setError((responseData && responseData.message) || 'Could not load policies')
        setLoading(false)
        return
      }
      const sortedData = (responseData.results as Policy[]).sort((a, b) =>
        a.name.localeCompare(b.name)
      )
      setPolicies(sortedData)
      setLoading(false)
    } catch (error) {
      setPolicies([])
      setError(ensureError(error))
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!loading && policies && policies.length === 0 && !error) {
      load()
    }
  }, [policies])

  if (error) {
    return (
      <Container className="mb-5 mt-100">
        <h2>Error</h2>
        <Highlight>{JSON.stringify(error, null, 2)}</Highlight>
      </Container>
    )
  }

  return policies ? (
    <div>
      <PageHeader hasBorderBottom load={load} loading={loading} mobileBreakpoint={1200}>
        <Breadcrumb title={breadcrumbTitle} />
      </PageHeader>
      <PaddedContent>
        <CardGrid>
          {policies.map((p) => (
            <PolicyCard
              key={p.id}
              name={p.name}
              policyIconVariant="loaded"
              onClick={() => history.push(`/ui/policies/${p.id}`)}
            />
          ))}
        </CardGrid>
      </PaddedContent>
    </div>
  ) : (
    <div />
  )
}

export default Policies
