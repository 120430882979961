import { useAppContext } from './appContext'

function authorizerBaseUrl() {
  const authorizerService = process.env.REACT_APP_AUTHORIZER_SERVICE_URL || window.location.origin
  const url = new URL(authorizerService)
  if (url.port === '3000') {
    url.port = '8383'
  }
  if (url.protocol === 'http:') {
    url.protocol = 'https:'
  }
  const urlString = url + ''
  // ensure the string returned ends with a /
  return urlString.endsWith('/') ? urlString : urlString + '/'
}

const getAuthorizerApiUrl = () => {
  return authorizerBaseUrl() + 'api/v1'
}

export const authorizerUrl = authorizerBaseUrl()

const buildHeaders = (apiKey?: string) => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  if (apiKey) {
    headers.append('Authorization', `Basic ${apiKey}`)
  }
  return headers
}

const fetchAuthorizerApi = async (
  method: RequestInit['method'],
  path: string,
  body?: string,
  apiKey?: string
) => {
  try {
    const response = await fetch(`${getAuthorizerApiUrl()}${path}`, {
      method,
      headers: buildHeaders(apiKey),
      body,
    })
    return await response.json()
  } catch (err) {
    return err
  }
}

export const useAuthorizerApi = () => {
  const context = useAppContext()
  if (!context) {
    return {
      query: (method: RequestInit['method'], path: string, body?: string, apiKey?: string) =>
        fetchAuthorizerApi(method, path, body, apiKey || ''),
    }
  } else {
    const { apiKey: defaultApiKey } = context
    return {
      query: (method: RequestInit['method'], path: string, body?: string, apiKey?: string) =>
        fetchAuthorizerApi(method, path, body, apiKey ?? defaultApiKey),
    }
  }
}
