import './App.css'

import React, { useEffect } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { theme } from '@aserto/aserto-react-components'

import NavBar from './components/NavBar'
import { GlobalStyle } from './globalStyles'
import { useAppContext } from './utils/appContext'
import history from './utils/history'
import ApiDocs from './views/ApiDocs'
import { DirectoryBrowser } from './views/DirectoryBrowser'
import Home from './views/Home'
import Login from './views/Login'
import Playground from './views/Playground'
import Policies from './views/Policies'
import Policy from './views/Policy'
import { SysInformation } from './views/SysInformation'
import UserView from './views/UserView'

function App() {
  const { loadUsers } = useAppContext()
  const { apiKey } = useAppContext()
  // use an effect to load the Aserto access map
  useEffect(() => {
    if (apiKey !== undefined) {
      loadUsers()
    }
  }, [loadUsers, apiKey])

  if (apiKey === undefined) {
    history.push('/auth')
    return (
      <ThemeProvider theme={theme}>
        <Login />
      </ThemeProvider>
    )
  }
  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <div id="app">
          <NavBar />
          <div>
            <Switch>
              <Route component={Home} exact path="/" />
              <Route component={Login} exact path="/auth" />
              <Route component={DirectoryBrowser} exact path="/ui/directory" />
              <Route component={UserView} path="/ui/directory/:id" />
              <Route component={Policies} exact path="/ui/policies" />
              <Route component={Policy} path="/ui/policies/:id" />
              <Route component={ApiDocs} path="/ui/apidocs" />
              <Route component={Playground} path="/ui/playground" />
              <Route component={SysInformation} path="/ui/sys-info" />
            </Switch>
          </div>
        </div>
      </ThemeProvider>
    </Router>
  )
}

export default App
