import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Highlight, PaddedContent, PageHeader } from '@aserto/aserto-react-components'

import { SysInformationSection } from '../components/AppInformationSection'
import { SysInformation as SysInfo } from '../types'
import { useAuthorizerApi } from '../utils/useAuthorizerApi'

const pageTitle = 'System Information'

const Container = styled.div`
  padding: 20px;
`

const SysInformation: React.FC = () => {
  const [sysInformation, setSysInformation] = useState<SysInfo>()
  const [error, setError] = useState<unknown>()
  const { query } = useAuthorizerApi()

  const getSysInformation = async () => {
    try {
      const responseData = await query('get', '/info')
      setSysInformation(responseData)
    } catch (error) {
      setError(error)
    }
  }

  useEffect(() => {
    getSysInformation()
  }, [])

  return (
    <div>
      <PageHeader hasBorderBottom mobileBreakpoint={1200} title={pageTitle} />
      <PaddedContent mobileBreakPoint={1200}>
        {error && (
          <Container>
            <h3>Error</h3>
            <Highlight>{JSON.stringify(error, null, 2)}</Highlight>
          </Container>
        )}
        {sysInformation && <SysInformationSection sysInformation={sysInformation} />}
      </PaddedContent>
    </div>
  )
}

export { SysInformation }
