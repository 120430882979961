import React, { ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import { ActionableInput, Button } from '@aserto/aserto-react-components'

import logo from '../../assets/logo.png'
import { useAppContext } from '../../utils/appContext'
import history from '../../utils/history'

const ScreenContainer = styled.div`
  display: flex;
  align-items: stretch;
  background-color: ${({ theme }) => theme.primaryBlack};
  width: 100vw;
  height: 100vh;
  position: relative;
`

const Header = styled.div`
  height: 5.125rem;
  width: 100vw;
  background-color: ${({ theme }) => theme.grey20};
  border: 1px solid ${({ theme }) => theme.grey30};
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Body = styled.div`
  margin-top: 5.125rem;
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
`

const LoginBoxContainer = styled.div`
  width: 33.75rem;
  height: 19.4375rem;
  background-color: ${({ theme }) => theme.grey10};
  border-radius: 6px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const LogoImg = styled.img`
  width: 3rem;
  height: 3rem;
  margin-left: 1.3125rem;
`

const AsertoTitle = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
  color: ${({ theme }) => theme.fullWhite};
  margin-left: 6px;
`

const WelcomeContainer = styled.div`
  font-size: var(--title-font-size);
  color: ${({ theme }) => theme.grey100};
  height: 3.75rem;
  background-color: ${({ theme }) => theme.grey20};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 0 0 0 1.25rem;
  font-family: Roboto;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 24px;
`

const MessageContainer = styled.div`
  padding: 0 1.25rem;
  width: 100%;
  height: 44px;
  font-family: Roboto;
  font-size: 14px;
  color: ${({ theme }) => theme.grey70};
  line-height: 22px;
`

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
`
const Input = styled(ActionableInput)`
  padding: 0 1.25rem;
  width: 32rem;
  align-self: center;
`

const ContinueButtonContainer = styled(Button)`
  width: 95%;
  height: 36px;
  margin: 0 auto 1rem;
`
const Login = () => {
  const [formApiKey, setFormApiKey] = useState('')
  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    setFormApiKey(event.currentTarget.value)
  }
  const handleSubmit = (event: React.FormEvent) => {
    setApiKey(formApiKey)
    event.preventDefault()
    history.push('/')
  }
  const { setApiKey } = useAppContext()
  return (
    <ScreenContainer>
      <Header>
        <LogoImg alt="Aserto logo" src={logo} width="120" />
        <AsertoTitle>Aserto</AsertoTitle>
      </Header>
      <Body>
        <LoginBoxContainer>
          <WelcomeContainer>Welcome to Aserto OneBox!</WelcomeContainer>
          <MessageContainer>
            To connect to the Authorizer, copy and past the Authorizer API key from the policy
            settings page located in the Aserto Console. If your config doesn't have an API key, you
            can leave it empty.
          </MessageContainer>
          <InputContainer>
            <Input
              label="Authorizer API key"
              placeholder="API Key"
              shouldShowHideShowButton
              type="password"
              onChange={handleInput}
            />
          </InputContainer>
          <ContinueButtonContainer type="submit" onClick={handleSubmit}>
            Continue
          </ContinueButtonContainer>
        </LoginBoxContainer>
      </Body>
    </ScreenContainer>
  )
}

export default Login
