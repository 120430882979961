import React from 'react'
import styled from 'styled-components'

import { Col, Row } from '../../styles'
import { SysInformation } from '../../types'
import { formatDateStringToDateTimeString } from '../../utils/general'

const ListContainer = styled.div`
  padding: 20px;
  h4 {
    font-size: 18px;
    font-weight: bold;
  }
  section {
    margin-bottom: 40px;
  }
`

const BaseText = styled.span`
  color: ${({ theme }) => theme.grey70};
  font-size: 14px;
`

const LeftText = styled(BaseText)`
  color: #e7e7e7;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bold;
  width: 132px;
`

interface SysInformationSectionProps {
  sysInformation: SysInformation
}

const SysInformationSection: React.FC<SysInformationSectionProps> = ({ sysInformation }) => {
  const { build, system, version } = sysInformation
  return (
    <ListContainer>
      <section>
        <h4>Build</h4>
        <Row>
          <Col>
            <LeftText>Arch:</LeftText>
            <LeftText>Commit:</LeftText>
            <LeftText>Date:</LeftText>
            <LeftText>OS:</LeftText>
            <LeftText>Version:</LeftText>
          </Col>
          <Col>
            <BaseText>{build.arch}</BaseText>
            <BaseText>{build.commit}</BaseText>
            <BaseText>{formatDateStringToDateTimeString(build.date)}</BaseText>
            <BaseText>{build.os}</BaseText>
            <BaseText>{build.version}</BaseText>
          </Col>
        </Row>
      </section>
      <section>
        <h4>System</h4>
        <Row>
          <Col>
            <LeftText>Created At:</LeftText>
            <LeftText>Instance ID:</LeftText>
          </Col>
          <Col>
            <BaseText>{formatDateStringToDateTimeString(system.created_at)}</BaseText>
            <BaseText>{system.instance_id}</BaseText>
          </Col>
        </Row>
      </section>
      <section>
        <h4>Version</h4>
        <Row>
          <Col>
            <LeftText>Schema:</LeftText>
            <LeftText>System:</LeftText>
          </Col>
          <Col>
            <BaseText>{version.schema}</BaseText>
            <BaseText>{version.system}</BaseText>
          </Col>
        </Row>
      </section>
    </ListContainer>
  )
}

export { SysInformationSection }
