import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Breadcrumb, PaddedContent, PageHeader } from '@aserto/aserto-react-components'

import { SpinnerFull } from '../components/common/SpinnerFull'
import { UserDetails } from '../components/users/UserDetails'
import { User } from '../types'
import { useAppContext } from '../utils/appContext'
import { ensureError } from '../utils/general'
import { useAuthorizerApi } from '../utils/useAuthorizerApi'

const UserView = () => {
  const { id } = useParams<{ id: string }>()
  const { setError, setIdentity } = useAppContext()
  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const breadcrumbTitle = user ? user.display_name : ''
  const { query } = useAuthorizerApi()
  const load = async () => {
    try {
      setIsLoading(true)
      const userData = await query('get', `/dir/users/${id}`)
      const result = userData && userData.result
      setUser(result)
      setIsLoading(false)
    } catch (error) {
      setUser(null)
      setError(ensureError(error))
      setIsLoading(false)
    }
  }

  useEffect(() => {
    load()
  }, [id])

  return (
    <div>
      {isLoading && <SpinnerFull />}
      <PageHeader hasBorderBottom load={load} loading={isLoading} mobileBreakpoint={1200}>
        <Breadcrumb
          breadcrumbText="Directory"
          breadcrumbUrl="/ui/directory"
          title={breadcrumbTitle}
        />
      </PageHeader>
      <PaddedContent mobileBreakPoint={1200}>
        {user && <UserDetails user={user} onClickSetIdentity={setIdentity} />}
      </PaddedContent>
    </div>
  )
}

export default UserView
