import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@aserto/aserto-react-components'

const SpinnerContainer = styled.div<{ $opacity?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.primaryBlack};
  opacity: ${({ $opacity }) => $opacity};
  top: 0;
  z-index: 8;
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`

export type SpinnerFullProps = {
  opacity?: number
}

export const SpinnerFull: React.FC<SpinnerFullProps> = ({ opacity }) => (
  <SpinnerContainer $opacity={opacity || 1}>
    <Spinner />
  </SpinnerContainer>
)
