import { useEffect, useState } from 'react'

export const formatDateStringToDateTimeString = (date: string): string => {
  const dateInstance = new Date(date)
  return `${dateInstance.toLocaleDateString()} - ${dateInstance.toLocaleTimeString()}`
}

export const useDeviceDetect = () => {
  const [isMobile, setMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth <= 920) {
      setMobile(true)
      return
    } else {
      setMobile(false)
      return
    }
  }, [])

  return { isMobile }
}

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

export function ensureError(error: unknown): Error {
  if (error instanceof Error) {
    return error
  }
  return Error(JSON.stringify(error, null, 2))
}
