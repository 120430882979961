import React from 'react'
import styled from 'styled-components'
import { Input, Select } from '@aserto/aserto-react-components'

const Col = styled.div`
  width: 100%;
`
const Row = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  ${Col}:first-of-type {
    padding-right: 10px;
  }
`

export type IdentityContextProps = {
  type: string
  setType: (s: string) => void
  identity: string | undefined
  setIdentity: (s: string) => void
}

export const IdentityContext: React.FC<IdentityContextProps> = ({
  type,
  setType,
  identity,
  setIdentity,
}) => {
  const identityOptions = [
    {
      label: 'Anonymous',
      value: 'IDENTITY_TYPE_NONE',
    },
    {
      label: 'JWT',
      value: 'IDENTITY_TYPE_JWT',
    },
    {
      label: 'Subject',
      value: 'IDENTITY_TYPE_SUB',
    },
  ]

  // if type wasn't specified and identity is set, use Manual
  if (!type && identity) {
    type = 'IDENTITY_TYPE_SUB'
  }

  const defaultMode = identityOptions.find((i) => i.value === type) ?? identityOptions[0]

  return (
    <Row>
      <Col>
        <Select
          defaultValue={defaultMode}
          options={identityOptions}
          onChange={(option) => setType(option?.value.toString() ?? '')}
        />
      </Col>
      <Col style={{ marginTop: 10, marginBottom: 10 }}>
        {type !== 'IDENTITY_TYPE_NONE' && (
          <Input
            placeholder={type === 'IDENTITY_TYPE_JWT' ? 'JWT' : 'identity'}
            value={identity || ''}
            onChange={(e) => setIdentity(e.target.value)}
          />
        )}
      </Col>
    </Row>
  )
}
