import { Location } from 'history'
import React from 'react'
import { Nav } from 'react-bootstrap'
import { match } from 'react-router'
import { NavLink as RouterNavLink } from 'react-router-dom'
import { NavBar as AsertoNavBar } from '@aserto/aserto-react-components'

const NavBar = () => {
  // isActive helper
  const checkActive = (match: match | null, location: Location) => {
    if (!location) {
      return false
    }
    const { pathname } = location
    return pathname === '/'
  }

  return (
    <AsertoNavBar>
      <Nav as="ul" className="mr-auto">
        <Nav.Item as="li">
          <Nav.Link
            activeClassName="router-link-exact-active"
            as={RouterNavLink}
            eventKey="1"
            isActive={checkActive}
            to="/"
          >
            Home
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            activeClassName="router-link-exact-active"
            as={RouterNavLink}
            eventKey="2"
            to="/ui/policies"
          >
            Policy browser
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            activeClassName="router-link-exact-active"
            as={RouterNavLink}
            eventKey="3"
            to="/ui/playground"
          >
            Policy playground
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            activeClassName="router-link-exact-active"
            as={RouterNavLink}
            eventKey="4"
            to="/ui/apidocs"
          >
            API browser
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            activeClassName="router-link-exact-active"
            as={RouterNavLink}
            eventKey="5"
            to="/ui/directory"
          >
            Directory browser
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <div className="navbar-nav">
        <Nav.Item className="left-item">
          <Nav.Link
            activeClassName="router-link-exact-active"
            as={RouterNavLink}
            eventKey="6"
            to="/ui/sys-info"
          >
            System Information
          </Nav.Link>
        </Nav.Item>
      </div>
    </AsertoNavBar>
  )
}

export default NavBar
