import React, { useCallback, useContext, useState } from 'react'

import { User } from '../types'
import { ensureError } from './general'
import { useAuthorizerApi } from './useAuthorizerApi'

interface AppContextProps {
  users: User[]
  setUsers: (users: User[]) => void
  identity: string | undefined
  setIdentity: (identity: string | undefined) => void
  loadUsers: () => Promise<void>
  input: string | undefined
  setInput: (input: string) => void
  query: string | undefined
  setQuery: (query: string | undefined) => void
  error: Error | undefined
  setError: (error: Error | undefined) => void
  loading: boolean
  apiKey: string | undefined
  setApiKey: (apiKey: string) => void
}

const AppContext = React.createContext<AppContextProps>({
  users: [],
  setUsers: () => {},
  identity: undefined,
  setIdentity: () => {},
  loadUsers: async () => {},
  input: undefined,
  setInput: () => {},
  query: undefined,
  setQuery: () => {},
  error: undefined,
  setError: () => {},
  loading: false,
  apiKey: undefined,
  setApiKey: () => {},
})

export const useAppContext = () => useContext(AppContext)

export const AppContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [users, setUsers] = useState<User[]>([])
  const [identity, setIdentity] = useState<string>()
  const [query, setQuery] = useState<string>()
  const [input, setInput] = useState<string>()
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)
  const [apiKey, setApiKey] = useState<string>()
  const { query: apiQuery } = useAuthorizerApi()

  const loadUsers = useCallback(async () => {
    try {
      setLoading(true)
      const responseData = await apiQuery(
        'get',
        '/dir/users?page.size=-1&fields.mask=id,display_name,picture,email',
        undefined,
        apiKey
      )

      const data = responseData && responseData.results
      setUsers(data || [])
      setLoading(false)
    } catch (error) {
      setUsers([])
      setError(ensureError(error))
      setLoading(false)
    }
  }, [apiKey])

  return (
    <AppContext.Provider
      value={{
        users,
        setUsers,
        identity,
        setIdentity,
        loadUsers,
        input,
        setInput,
        query,
        setQuery,
        error,
        setError,
        loading,
        apiKey,
        setApiKey,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
