import React from 'react'
import styled from 'styled-components'
import { Button, Highlight } from '@aserto/aserto-react-components'

import { User } from '../../../types'

const Container = styled.div`
  padding: 20px;
  div {
    margin-top: 20px;
  }
`

interface UserDetailsProps {
  user: User
  onClickSetIdentity: (id: string) => void
}

export const UserDetails: React.FC<UserDetailsProps> = ({ user, onClickSetIdentity }) => {
  return (
    <Container>
      <Button onClick={() => onClickSetIdentity(user.id)}>Set Identity</Button>
      <div>
        <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
      </div>
    </Container>
  )
}
