import React, { useLayoutEffect } from 'react'
import styled from 'styled-components'

import { useDeviceDetect } from '../../../utils/general'

const FullScreenContainer = styled.div<{ $show?: boolean }>`
  flex: 1;
  @media (min-width: 920px) {
    width: 50%;
  }
  @media (max-width: 920px) {
    ${({ $show }) => {
      return $show ? 'display: block' : 'display: none'
    }};
    width: 100%;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    height: 100%;
    background-color: ${({ theme }) => theme.primaryBlack};
    z-index: 200;
  }
`

const CloseButton = styled.div`
  display: none;
  cursor: pointer;
  @media (max-width: 920px) {
    display: block;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 201;
    font-size: 16px;
  }
`

export type FullScreenOnMobileProps = {
  children: React.ReactElement | string
  show: boolean
  onCloseMobile?: () => void
}

export const FullScreenOnMobile: React.FC<FullScreenOnMobileProps> = ({
  children,
  onCloseMobile,
  show,
}) => {
  const { isMobile } = useDeviceDetect()
  const removeOverflowFromBody = () => {
    document!.querySelector('body')!.style.overflow = 'hidden'
  }

  const addOverflowToBody = () => {
    document!.querySelector('body')!.style.overflow = 'auto'
  }

  useLayoutEffect(() => {
    if (show && isMobile) {
      removeOverflowFromBody()
    } else {
      addOverflowToBody()
    }
    return () => addOverflowToBody()
  }, [show, isMobile])

  return (
    <FullScreenContainer $show={show}>
      <CloseButton onClick={onCloseMobile}>Close</CloseButton>
      {children}
    </FullScreenContainer>
  )
}
