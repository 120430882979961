import React from 'react'
import { PaddedContent, PageHeader } from '@aserto/aserto-react-components'
import AsertoSwaggerUI from '@aserto/aserto-swagger-ui'

import { authorizerUrl } from '../utils/useAuthorizerApi'

const ApiDocs = () => {
  const pageTitle = 'API browser'
  return (
    <div>
      <PageHeader hasBorderBottom mobileBreakpoint={1200} title={pageTitle} />
      <PaddedContent mobileBreakPoint={1200}>
        <AsertoSwaggerUI url={`${authorizerUrl}openapi/swagger.json`} />
      </PaddedContent>
    </div>
  )
}

export default ApiDocs
