import './PolicyBrowser.css'

import React, { useEffect, useState } from 'react'
import { Nav } from 'react-bootstrap'
import styled, { css } from 'styled-components'
import { Highlight, Spinner } from '@aserto/aserto-react-components'

import { PolicyFile } from '../../../types'
import { useAuthorizerApi } from '../../../utils/useAuthorizerApi'
import { FullScreenOnMobile } from '../../common/FullScreenOnMobile'
import { SpinnerFull } from '../../common/SpinnerFull'

const Col = styled.div`
  display: flex;
  @media (min-width: 920px) {
    width: 50%;
  }
  @media (max-width: 920px) {
    flex: 1;
    flex-direction: column;
  }
`

const MobileCol = styled(FullScreenOnMobile)`
  display: flex;
  @media (min-width: 920px) {
    width: 50%;
  }
  @media (max-width: 920px) {
    flex: 1;
    flex-direction: column;
  }
`

const CenteredSpinnerContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

const Content = styled.div<{
  $flex?: boolean
  $fixed?: boolean
  $paddingTop?: number
  $shouldPad?: boolean
}>`
  border-bottom: 1px solid ${({ theme }) => theme.grey20};
  border-right: 1px solid ${({ theme }) => theme.grey20};
  color: ${({ theme }) => theme.grey100};
  ${({ $flex }) => ($flex ? 'flex: 1' : '')};
  padding: 20px;
  font-size: 18px;
  font-weight: bold;

  @media (min-width: 920px) {
    ${({ $fixed }) => {
      return $fixed
        ? css`
            position: fixed;
            margin-top: -2px;
            width: 50%;
            background-color: ${({ theme }) => theme.primaryBlack};
            display: flex;
          `
        : 'height: 100%;'
    }}

    ${({ $paddingTop }) => {
      return `padding-top: ${$paddingTop}px`
    }}
  }

  @media (max-width: 920px) {
    ${({ $fixed, $shouldPad }) => {
      return $fixed
        ? css`
            position: fixed;
            top: ${$shouldPad ? 169 : 0}px;
            width: 100%;
            z-index: 2;
            background-color: #121212;
          `
        : 'padding-top: 90px;'
    }}
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
`

const Row = styled.div`
  @media (max-width: 920px) {
    flex-wrap: wrap;
  }
  display: flex;
`

const PolicyLineItem = styled(Nav.Item)`
  margin-bottom: 20px;
  border-radius: 5px;
  font-weight: 600;
  a {
    background-color: ${({ theme }) => theme.grey20};
    color: ${({ theme }) => theme.grey20};
    &:hover {
      background-color: ${({ theme }) => theme.grey40};
      color: ${({ theme }) => theme.grey100};
    }
    font-size: 14px;
  }
`

export type PolicyBrowserProps = {
  policies: Array<PolicyFile>
  id: string
}

export const PolicyBrowser: React.FC<PolicyBrowserProps> = ({ policies, id }) => {
  const [error, setError] = useState<unknown>()
  const [loading, setLoading] = useState(false)
  const [moduleId, setModuleId] = useState<string | null>('')
  const [policy, setPolicy] = useState<string>('')
  const [isModalOpenOnMobile, setIsModalOpenOnMobile] = useState<boolean>(false)
  const { query } = useAuthorizerApi()

  const load = async () => {
    setLoading(true)
    try {
      const responseData = await query('get', `/policy/modules/${moduleId}?policy_id=${id}`)
      setPolicy(responseData && responseData.module && responseData.module.content)
    } catch (error) {
      setPolicy('')
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (moduleId) {
      load()
    }
  }, [moduleId])

  if (!moduleId) {
    setModuleId(policies[0].id)
    return <div />
  }

  const onCloseMobile = () => {
    setIsModalOpenOnMobile(false)
  }

  return (
    <div>
      {!policies && loading ? (
        <SpinnerFull />
      ) : (
        <Row>
          <Col>
            <Content $fixed $shouldPad>
              Policy file
            </Content>
            <Content $flex $paddingTop={88}>
              <Nav
                className="flex-column"
                defaultActiveKey={moduleId}
                variant="pills"
                onSelect={(key) => {
                  setModuleId(key)
                  setIsModalOpenOnMobile(true)
                }}
              >
                {policies &&
                  policies.map((p: PolicyFile) => (
                    <PolicyLineItem key={p.id}>
                      <Nav.Link className="light-pills" eventKey={p.id}>
                        <span>{p.name}</span>
                      </Nav.Link>
                    </PolicyLineItem>
                  ))}
              </Nav>
            </Content>
          </Col>
          <MobileCol show={isModalOpenOnMobile} onCloseMobile={onCloseMobile}>
            <>
              <Content $fixed>Definition</Content>
              <Content $flex $paddingTop={88}>
                {loading && (
                  <CenteredSpinnerContainer>
                    <Spinner />
                  </CenteredSpinnerContainer>
                )}
                {error ? (
                  <Highlight>{JSON.stringify(error, null, 2)}</Highlight>
                ) : (
                  !loading && policy && <Highlight>{policy}</Highlight>
                )}
              </Content>
            </>
          </MobileCol>
        </Row>
      )}
    </div>
  )
}
