import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  DirectoryBrowserGrid,
  Input,
  PaddedContent,
  PageHeader,
} from '@aserto/aserto-react-components'

import { SpinnerFull } from '../../components/common/SpinnerFull'
import { useAppContext } from '../../utils/appContext'
import { scrollToTop } from '../../utils/general'

const initialPage = 1

const PageHeaderInput = styled(Input)`
  width: 220px;
  margin-left: 20px;
`

export const DirectoryBrowser = () => {
  const history = useHistory()
  const [filter, setFilter] = useState('')
  const [page, setPage] = useState<number>(1)
  const pageTitle = 'Directory browser'
  const { loadUsers, users, loading: isLoading } = useAppContext()

  useEffect(() => {
    if (users?.length === 0) {
      loadUsers()
    }
  }, [])

  useEffect(() => {
    if (filter) {
      scrollToTop()
    }
  }, [page, filter])

  const onClickCard = (id: string) => {
    history.push(`/ui/directory/${id}`)
  }

  return (
    <div>
      <PageHeader
        hasBorderBottom
        load={() => {
          setPage(initialPage)
          setFilter('')
          loadUsers()
        }}
        loading={isLoading}
        mobileBreakpoint={1200}
        title={pageTitle}
      >
        <PageHeaderInput
          data-testid="filter-input"
          placeholder="Filter"
          value={filter}
          onChange={(e) => {
            setPage(initialPage)
            setFilter(e.target.value)
          }}
        />
      </PageHeader>
      {isLoading ? (
        <SpinnerFull />
      ) : (
        <PaddedContent>
          <DirectoryBrowserGrid
            filter={filter}
            pageSize={30}
            users={users}
            onClickCard={onClickCard}
          />
        </PaddedContent>
      )}
    </div>
  )
}
