import './RequestEditor.css'

import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Highlight, PlayButton, Select, TextArea } from '@aserto/aserto-react-components'

import { useAppContext } from '../utils/appContext'
import { ensureError } from '../utils/general'
import { useAuthorizerApi } from '../utils/useAuthorizerApi'
import { IdentityContext } from './IdentityContext'

const Col = styled.div`
  display: flex;
  @media (min-width: 680px) {
    width: 50%;
  }
  @media (max-width: 900px) {
    flex: 1;
    flex-direction: column;
  }
`

const Text = styled.div`
  margin: auto 20px auto auto;
`

const Content = styled.div<{ $flex?: boolean; $fixed?: boolean; $paddingTop?: number }>`
  border-bottom: 1px solid ${({ theme }) => theme.grey20};
  border-right: 1px solid ${({ theme }) => theme.grey20};
  color: ${({ theme }) => theme.grey100};
  ${({ $flex }) => ($flex ? 'flex: 1' : '')};
  display: flex;
  padding: 20px;
  font-size: 18px;
  font-weight: bold;
  @media (min-width: 620px) {
    ${({ $fixed }) => {
      return $fixed
        ? css`
            height: 83px;
            margin-top: -2px;
            width: 50%;
            background-color: ${({ theme }) => theme.primaryBlack};
            display: flex;
            position: fixed;
            span {
              margin: auto 0px;
            }
          `
        : ''
    }}

    ${({ $paddingTop }) => {
      return `padding-top: ${$paddingTop}px`
    }}
  }

  @media (max-width: 720px) and (min-width: 680px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
`

const Row = styled.div`
  flex-wrap: wrap;
  margin-bottom: 20px;
  display: flex;
`

const HighlightWrapper = styled.div`
  width: 100%;
  padding: 20px 5px;
  overflow-y: scroll;
  min-width: 200px;
  min-height: 200px;
`

const RequestEditor = () => {
  const { identity, setIdentity, query, setQuery, input, setInput } = useAppContext()
  const { query: apiQuery } = useAuthorizerApi()
  const [type, setType] = useState(identity ? 'IDENTITY_TYPE_SUB' : 'IDENTITY_TYPE_NONE')
  const [output, setOutput] = useState<{}>()

  const requestOptions = [
    {
      label: 'query',
      value: 'query',
    },
  ]

  const execute = async () => {
    try {
      const body = {
        identityContext: {
          type,
          identity: identity,
        },
        policyContext: {
          id: null,
        },
        query,
        input,
      }

      const responseData = await apiQuery('post', '/authz/query', JSON.stringify(body))
      setOutput(responseData && responseData.results)
    } catch (error) {
      setOutput(ensureError(error).message)
    }
  }

  return (
    <div>
      <Row>
        <Col>
          <Content $fixed>
            <Row
              style={{
                flex: 2,
              }}
            >
              <Text>Request:</Text>
              <Select
                defaultValue={requestOptions[0]}
                options={requestOptions}
                style={{
                  flex: 1,
                  margin: 'auto',
                }}
              />
            </Row>
            <Row
              style={{
                justifyContent: 'flex-end',
                flex: 1,
              }}
            >
              <PlayButton onClick={execute} />
              {/*<div className="request-editor-play-icon" />*/}
            </Row>
          </Content>
          <Content $flex $paddingTop={69}>
            <div style={{ maxHeight: 'calc(100vh - 295px)', width: '100%' }}>
              {/* Identity Context */}
              <div className="request-body-title">Identity context</div>
              <IdentityContext
                identity={identity}
                setIdentity={setIdentity}
                setType={setType}
                type={type}
              />

              {/* Query */}
              <div className="request-body-title">Query</div>
              <TextArea
                className="request-body-area"
                style={{ height: 'calc((100vh - 540px) * 2 / 3)' }}
                // @ts-expect-error TextArea typing of `value` prop is broken
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />

              {/* Input */}
              <div className="request-body-title">Input</div>
              <TextArea
                className="request-body-area"
                style={{ height: 'calc((100vh - 490px) / 3)' }}
                // @ts-expect-error TextArea typing of `value` prop is broken
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
            </div>
          </Content>
        </Col>
        <Col>
          <Content $fixed>
            <span>Output</span>
          </Content>
          <Content
            $flex
            $paddingTop={69}
            style={{
              maxHeight: 'calc(100vh - 205px)',
            }}
          >
            <HighlightWrapper>
              <Highlight style={{ maxHeight: 'calc(100vh - 255px)' }}>
                {JSON.stringify(output, null, 2)}
              </Highlight>
            </HighlightWrapper>
          </Content>
        </Col>
      </Row>
    </div>
  )
}

export default RequestEditor
