import React from 'react'
import { PaddedContent, PageHeader } from '@aserto/aserto-react-components'

import RequestEditor from '../components/RequestEditor'

const RegoPlayground = () => {
  const pageTitle = 'Policy playground'
  return (
    <div>
      <PageHeader hasBorderBottom mobileBreakpoint={1200} title={pageTitle} />
      <PaddedContent mobileBreakPoint={1200}>
        <RequestEditor />
      </PaddedContent>
    </div>
  )
}

export default RegoPlayground
