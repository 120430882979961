import React from 'react'

import logo from '../assets/logo.png'

const Hero = () => (
  <div className="text-center hero my-3">
    <img alt="Aserto logo" className="mb-3 app-logo" src={logo} width="120" />
    <h1 className="mb-4">Aserto OneBox</h1>

    <p className="lead">View and manage Aserto developer OneBox.</p>
  </div>
)

export default Hero
